import { apolloClient } from "@/apollo"
import { ref } from "vue"
import moment from "moment"

import CheckReferralCode from "@/graphql/mutations/validation/checkReferralCode.gql"
import { ERROR } from "@/constants"

export const namespaced = true

export const state = {
  paymentAuthorizationForm: null,
  paymentAuthorizationDate: {
    date: null,
  },
  loading: false,
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  PARSE_REFERRAL_CODE(state, value) {
    state.paymentAuthorizationForm.referral_code = value
      .replace(/ /g, "")
      .toUpperCase()
  },
  CREATE_PAYMENT_AUTHORIZATION_FORM(state, value) {
    state.paymentAuthorizationForm = value
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null
    state[dateForm][field] = value
  },
}

export const actions = {
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  createPaymentAuthorizationForm({ commit }, paymentAuthorization) {
    commit("SET_DATE_FIELD", {
      model: paymentAuthorization,
      field: "date",
      dateForm: "paymentAuthorizationDate",
    })

    commit("CREATE_PAYMENT_AUTHORIZATION_FORM", {
      client_name: paymentAuthorization.client_name
        ? paymentAuthorization.client_name
        : "",
      responsible_party: paymentAuthorization.responsible_party
        ? paymentAuthorization.responsible_party
        : "",
      street_address: paymentAuthorization.street_address
        ? paymentAuthorization.street_address
        : "",
      zip_code: paymentAuthorization.zip_code
        ? paymentAuthorization.zip_code
        : "",
      signature: paymentAuthorization.signature
        ? paymentAuthorization.signature
        : "",
      signature_print: paymentAuthorization.signature_print
        ? paymentAuthorization.signature_print
        : "",
      date: paymentAuthorization.date,
      referral_code: paymentAuthorization.referral_code
        ? paymentAuthorization.referral_code
        : "",
    })
  },
  parseReferralCode({ commit }, value) {
    commit("PARSE_REFERRAL_CODE", value)
  },
  validateReferralCode({ state, commit, dispatch }) {
    if (state.paymentAuthorizationForm.referral_code) {
      commit("SET_LOADING", true)

      return apolloClient
        .mutate({
          mutation: CheckReferralCode,
          variables: {
            referral_code: state.paymentAuthorizationForm.referral_code,
          },
        })
        .then((response) => {
          let data = response.data.checkReferralCode

          if (!data.is_valid) {
            dispatch(
              "applicationFormValidation/setReferralCodeValidationMessage",
              data.message,
              {
                root: true,
              }
            )
          }
        })
        .catch((error) => {
          let notification = {
            type: ERROR,
            message: error,
          }

          dispatch("notification/add", notification, { root: true })
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    }
  },
}

export const getters = {}
