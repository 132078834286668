import ValidationService from "@/services/ValidationService"

export const namespaced = true

const developmentalHistoryObj = {
  crawled_years: null,
  crawled_months: null,
  crept_years: null,
  crept_months: null,
  walked_years: null,
  walked_months: null,
  toilet_trained_years: null,
  toilet_trained_months: null,
  first_word_years: null,
  first_word_months: null,
  use_of_couplets_years: null,
  use_of_couplets_months: null,
  word_phrases_years: null,
  word_phrases_months: null,
  sentences_years: null,
  sentences_months: null,
  conversational_language_years: null,
  conversational_language_months: null,
  read_years: null,
  read_months: null,
}

export const state = {
  developmentalHistoryValidation: new ValidationService(
    developmentalHistoryObj
  ),
  clientInfoValidation: new ValidationService({
    email: null,
    photo: null,
    issues: null,
  }),
  motherInfoValidation: new ValidationService({
    email: null,
  }),
  fatherInfoValidation: new ValidationService({
    email: null,
  }),
  guardianInfoValidation: new ValidationService({
    email: null,
  }),
  spouseInfoValidation: new ValidationService({
    email: null,
  }),
  goalValidation: new ValidationService({
    signature_1: null,
    date_1: null,
  }),
  agreementValidation: new ValidationService({
    signature: null,
    date: null,
  }),
  paymentAuthorizationValidation: new ValidationService({
    client_name: null,
    responsible_party: null,
    street_address: null,
    zip_code: null,
    signature: null,
    date: null,
    accept_agreement: null,
    referral_code: null,
  }),
}

export const mutations = {
  SET_REFERRAL_CODE_VALIDATION_MESSAGE(state, message) {
    state.paymentAuthorizationValidation.set("referral_code", message)
  },
  VALDIATE_IS_MONTH(state, { value, field, fieldLabel, validationService }) {
    if (value !== "" && value !== null) {
      if (!Number.isInteger(value)) {
        state[validationService].set(
          field,
          `${fieldLabel} must be positive integer`
        )
      } else if (value < 0 || value > 12) {
        state[validationService].set(
          field,
          `${fieldLabel} must be between zero and twelve`
        )
      }
    }
  },
  VALDIATE_FIELD_IS_POSITIVE_INTEGER(
    state,
    { value, field, fieldLabel, validationService }
  ) {
    if (value !== "" && value !== null) {
      if (value < 0) {
        state[validationService].set(
          field,
          `${fieldLabel} must be greater than or equal to zero`
        )
      } else if (!Number.isInteger(value)) {
        state[validationService].set(
          field,
          `${fieldLabel} must be positive integer`
        )
      }
    }
  },
  VALDIATE_ACCEPT_AGREEMENT(state, value) {
    if (value !== true) {
      state.paymentAuthorizationValidation.set(
        "accept_agreement",
        "This field cannot be blank"
      )
    }
  },
  VALDIATE_FIELD_NOT_EMPTY(
    state,
    { value, field, fieldLabel, validationService }
  ) {
    if (value === "" || value === null) {
      state[validationService].set(field, `${fieldLabel} cannot be blank`)
    }
  },
  VALDIATE_EMAIL(state, { email, field, validationService }) {
    if (email !== "" && email !== null && !validEmail(email)) {
      state[validationService].set(field, "Invalid email address")
    }
  },
}

export const actions = {
  clear({ state }) {
    state.clientInfoValidation.clear()
    state.motherInfoValidation.clear()
    state.fatherInfoValidation.clear()
    state.guardianInfoValidation.clear()
    state.spouseInfoValidation.clear()
    state.goalValidation.clear()
    state.agreementValidation.clear()
    state.paymentAuthorizationValidation.clear()
  },
  validateDevelopmentalHistory({ state, commit, rootState }) {
    state.developmentalHistoryValidation.clear()

    for (const property in developmentalHistoryObj) {
      property.includes("years")
        ? commit("VALDIATE_FIELD_IS_POSITIVE_INTEGER", {
            value:
              rootState.applicationFormDevelopmentalHistory
                .developmentalHistoryForm[property],
            field: property,
            fieldLabel: "This field",
            validationService: "developmentalHistoryValidation",
          })
        : commit("VALDIATE_IS_MONTH", {
            value:
              rootState.applicationFormDevelopmentalHistory
                .developmentalHistoryForm[property],
            field: property,
            fieldLabel: "This field",
            validationService: "developmentalHistoryValidation",
          })
    }
  },
  validateClientInfoPage({ state, commit, rootState }) {
    state.clientInfoValidation.clear()

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationForm.applicationForm.issues,
      field: "issues",
      fieldLabel: "This field",
      validationService: "clientInfoValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationForm.applicationPhoto,
      field: "photo",
      fieldLabel: "Profile Photo",
      validationService: "clientInfoValidation",
    })

    commit("VALDIATE_EMAIL", {
      email: rootState.applicationFormInfo.infoForm.email,
      field: "email",
      validationService: "clientInfoValidation",
    })
  },
  validateMotherInfoPage({ state, commit, rootState }) {
    state.motherInfoValidation.clear()

    commit("VALDIATE_EMAIL", {
      email: rootState.applicationFormInfo.motherInfoForm.email,
      field: "email",
      validationService: "motherInfoValidation",
    })
  },
  validateFatherInfoPage({ state, commit, rootState }) {
    state.fatherInfoValidation.clear()

    commit("VALDIATE_EMAIL", {
      email: rootState.applicationFormInfo.fatherInfoForm.email,
      field: "email",
      validationService: "fatherInfoValidation",
    })
  },
  validateGuardianInfoPage({ state, commit, rootState }) {
    state.guardianInfoValidation.clear()

    commit("VALDIATE_EMAIL", {
      email: rootState.applicationFormInfo.guardianInfoForm.email,
      field: "email",
      validationService: "guardianInfoValidation",
    })
  },
  validateSpouseInfoPage({ commit, rootState }) {
    state.spouseInfoValidation.clear()

    commit("VALDIATE_EMAIL", {
      email: rootState.applicationFormInfo.spouseInfoForm.email,
      field: "email",
      validationService: "spouseInfoValidation",
    })
  },
  validateGoalPage({ state, commit, rootState }) {
    state.goalValidation.clear()

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationFormGoal.goalForm.date_1,
      field: "date_1",
      fieldLabel: "Date",
      validationService: "goalValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationFormGoal.goalForm.signature_1,
      field: "signature_1",
      fieldLabel: "Signature",
      validationService: "goalValidation",
    })
  },
  validateAgreementPage({ state, commit, rootState }) {
    state.agreementValidation.clear()

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationFormAgreement.agreementForm.date,
      field: "date",
      fieldLabel: "Date",
      validationService: "agreementValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value: rootState.applicationFormAgreement.agreementForm.signature,
      field: "signature",
      fieldLabel: "Signature",
      validationService: "agreementValidation",
    })
  },
  setReferralCodeValidationMessage({ commit }, message) {
    commit("SET_REFERRAL_CODE_VALIDATION_MESSAGE", message)
  },
  validatePaymentAuthorizationPage({ state, commit, rootState, dispatch }) {
    state.paymentAuthorizationValidation.clear()

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .client_name,
      field: "client_name",
      fieldLabel: "Client Name",
      validationService: "paymentAuthorizationValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .responsible_party,
      field: "responsible_party",
      fieldLabel: "Responsible Party",
      validationService: "paymentAuthorizationValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .street_address,
      field: "street_address",
      fieldLabel: "Street Address",
      validationService: "paymentAuthorizationValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .zip_code,
      field: "zip_code",
      fieldLabel: "Zip Code",
      validationService: "paymentAuthorizationValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .signature,
      field: "signature",
      fieldLabel: "Signature",
      validationService: "paymentAuthorizationValidation",
    })

    commit("VALDIATE_FIELD_NOT_EMPTY", {
      value:
        rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm
          .date,
      field: "date",
      fieldLabel: "Date",
      validationService: "paymentAuthorizationValidation",
    })

    commit(
      "VALDIATE_ACCEPT_AGREEMENT",
      rootState.applicationForm.applicationForm.accept_agreement
    )

    return dispatch(
      "applicationFormPaymentAuthorization/validateReferralCode",
      null,
      {
        root: true,
      }
    )
  },
}

let validEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
