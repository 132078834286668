import ApplicationFormDeletePhoto from "@/graphql/mutations/application-form/deletePhoto.gql"
import UpdateApplicationForm from "@/graphql/mutations/application-form/update.gql"
import { apolloClient } from "@/apollo"

import {
  APPLICATION_FORM_CATEGORY_ADULT,
  APPLICATION_FORM_CATEGORY_CHILD,
  APPLICATION_FORM_CLIEN_LIVE_WITH_OTHER_STATUSES,
  APPLICATION_FORM_COMPLETED_BY_OTHER,
  APPLICATION_FORM_DRAFT,
  APPLICATION_FORM_SUBMITTED,
  APPLICATION_FORM_INCOMPLETE,
  APPLICATION_FORM_ASSIGNED,
  APPLICATION_FORM_ARCHIVED,
} from "@/constants/application-form.js"

import {
  INITIAL_EVALUATION_FEE,
  MONTHLY_FEE,
} from "@/constants/application-form-discount-types.js"

import { NO_PROFILE_IMAGE } from "@/constants"

import { ref } from "vue"
import moment from "moment"

export const namespaced = true

export const state = {
  deletingPhoto: false,
  autosaving: false,
  loading: false,

  to: ref(new Date()),

  application: null,
  fee: null,
  applicationPhoto: null,
  applicationForm: null,

  guideForm: {},

  productPeriodsForm: [],
  applicationFormGuides: [],
  genders: [],
  familyApplicationFormPageAssignments: [],
  applicationFormClientLiveWithAll: [],
  applicationFormCompletedByAll: [],
  educations: [],
  chapters: [],
  childInfos: [],
  skills: [],
  discounts: [],
  initialFeeDiscounts: [],
  monthlyFeeDiscounts: [],

  goalsAssignmentPage: null,
  agreementAssignmentPage: null,
  paymentAuthorizationAssignmentPage: null,
  paymentAuthorizationAgreementAssignmentPage: null,

  yesNoOptions: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],

  yesNoNotSureOptions: [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
    { value: 3, label: "Not Sure" },
  ],

  initialEvaluationFeeDiscountTypeId: INITIAL_EVALUATION_FEE,
  monthlyFeeDiscountTypeId: MONTHLY_FEE,
}

export const mutations = {
  SET_DELETING_PHOTO(state, value) {
    state.deletingPhoto = value
  },
  SET_AUTOSAVING(state, value) {
    state.autosaving = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_APPLICATION_PHOTO(state, url) {
    state.applicationPhoto = url
  },
  SET_APPLICATION(state, value) {
    state.application = value
  },
  SET_FEE(state, fee) {
    state.fee = fee
  },
  CREATE_APPLICATION_FORM(state, value) {
    state.applicationForm = value
  },
  CREATE_GUIDE_FORM(state, allGuides) {
    allGuides.forEach((item) => {
      state.guideForm[item.id] = false
    })

    state.application.guides.forEach((item) => {
      state.guideForm[item.id] = true
    })
  },
  FORMAT_DATE_TO_MYSQL(state, { dateForm, form, field }) {
    state[form][field] = moment(state[dateForm][field]).format("YYYY-MM-DD")
  },
  SET_DISCOUNTS(state, value) {
    state.discounts = value
  },
  SET_INITIAL_FEE_DISCOUNTS(state, value) {
    state.initialFeeDiscounts = value
  },
  SET_MONTHLY_FEE_DISCOUNTS(state, value) {
    state.monthlyFeeDiscounts = value
  },
  SET_PAGE_ASSIGNMENTS(state, pageAssignments) {
    state.goalsAssignmentPage = pageAssignments.goalsAssignmentPage
    state.agreementAssignmentPage = pageAssignments.agreementAssignmentPage
    state.paymentAuthorizationAssignmentPage =
      pageAssignments.paymentAuthorizationAssignmentPage
    state.paymentAuthorizationAgreementAssignmentPage =
      pageAssignments.paymentAuthorizationAgreementAssignmentPage
  },
  SET_APPLICATION_FORM_COMPLETED_BY_ALL(state, value) {
    state.applicationFormCompletedByAll = value
  },
  SET_APPLICATION_FORM_CLIENT_LIVE_WITH_ALL(state, value) {
    state.applicationFormClientLiveWithAll = value
  },
  SET_GENDERS(state, value) {
    state.genders = value
  },
  SET_DATE_FIELD(state, { dateForm, field, model }) {
    let value =
      model && model[field] ? ref(new Date(`${model[field]}T00:00`)) : null

    state[dateForm][field] = value
  },
  SET_EDUCATIONS(state, educations) {
    state.educations = educations
  },
  SET_CHAPTERS(state, chapters) {
    state.chapters = chapters
  },
  SET_APPLICATION_FORM_GUIDES(state, applicationFormGuides) {
    state.applicationFormGuides = applicationFormGuides
  },
  SET_CHILD_INFOS(state, childInfos) {
    state.childInfos = childInfos
  },
}

export const actions = {
  back({ dispatch }) {
    dispatch("applicationFormValidation/clear", null, {
      root: true,
    })

    dispatch(
      "applicationFormSidePage/setInitPageIndexWithoutValidation",
      null,
      {
        root: true,
      }
    )
  },
  setChildInfos({ commit }, childInfos) {
    commit("SET_CHILD_INFOS", childInfos)
  },
  applyDiscounts({ commit }, discounts) {
    commit("SET_DISCOUNTS", discounts)
  },
  applyInitialFeeDiscounts({ commit }, discounts) {
    commit("SET_INITIAL_FEE_DISCOUNTS", discounts)
  },
  applyMonthlyFeeDiscounts({ commit }, discounts) {
    commit("SET_MONTHLY_FEE_DISCOUNTS", discounts)
  },
  init({ commit, dispatch, state }, applicationForm) {
    dispatch(
      "applicationFormSidePage/setInitPageIndexWithoutValidation",
      null,
      {
        root: true,
      }
    )

    dispatch(
      "applicationFormSidePage/setPages",
      applicationForm.type.category.parts,
      {
        root: true,
      }
    )

    let {
      fee,
      info,
      childInfos,
      motherInfo,
      fatherInfo,
      guardianInfo,
      spouseInfo,
      agreement,
      aware,
      behaviour,
      developmentalHistory,
      goal,
      educationalHistory,
      health,
      medicalHistory,
      paymentAuthorization,
      discounts,
      educationalPlacements,
      physicalActivities,
      handPreferenceOthers,
    } = applicationForm

    commit("SET_APPLICATION", applicationForm)

    commit("SET_APPLICATION_FORM_GUIDES", applicationForm.type.category.guides)

    commit("CREATE_GUIDE_FORM", applicationForm.type.category.guides)

    commit("CREATE_APPLICATION_FORM", {
      id: applicationForm.id,
      today_date: applicationForm.today_date,
      mailing_address: applicationForm.mailing_address,
      client_live_with_id: applicationForm.client_live_with_id,
      live_with_other_description: applicationForm.live_with_other_description,
      was_adopted: applicationForm.was_adopted,
      adopted_age: applicationForm.adopted_age,
      completed_by_id: applicationForm.completed_by_id,
      completed_by_other_description:
        applicationForm.completed_by_other_description,
      skype_name: applicationForm.skype_name,
      issues: applicationForm.issues,
      gender_id: applicationForm.gender_id,

      accept_agreement: applicationForm.accept_agreement,
      status_id: applicationForm.status_id,
      has_families_recommendation: applicationForm.has_families_recommendation,
      families_recommendation: applicationForm.families_recommendation,
      physical_motor_skill_other_description:
        applicationForm.physical_motor_skill_other_description,
      hand_preference_other_description:
        applicationForm.hand_preference_other_description,
      chapter_id: applicationForm.chapter_id,
    })

    commit("SET_APPLICATION_PHOTO", applicationForm.photo)

    commit("SET_FEE", fee)

    dispatch(
      "applicationFormHealth/setPhysicalActivities",
      physicalActivities,
      { root: true }
    )

    dispatch(
      "applicationFormSkill/setHandPreferenceOthers",
      handPreferenceOthers,
      { root: true }
    )

    dispatch("setChildInfos", childInfos)

    dispatch("applicationFormInfo/createInfoForm", info, { root: true })

    dispatch("applicationFormInfo/createMotherInfoForm", motherInfo, {
      root: true,
    })

    dispatch("applicationFormInfo/createFatherInfoForm", fatherInfo, {
      root: true,
    })

    dispatch("applicationFormInfo/createGuardianInfoForm", guardianInfo, {
      root: true,
    })

    dispatch("applicationFormInfo/createSpouseInfoForm", spouseInfo, {
      root: true,
    })

    dispatch("applicationFormAgreement/createAgreementForm", agreement, {
      root: true,
    })

    dispatch("applicationFormAware/createAwareForm", aware, {
      root: true,
    })

    dispatch("applicationFormBehaviour/createBehaviourForm", behaviour, {
      root: true,
    })

    dispatch(
      "applicationFormDevelopmentalHistory/createDevelopmentalHistoryForm",
      developmentalHistory,
      {
        root: true,
      }
    )

    dispatch("applicationFormGoal/createGoalForm", goal, {
      root: true,
    })

    dispatch("applicationFormHealth/createHealthForm", health, {
      root: true,
    })

    dispatch(
      "applicationFormMedicalHistory/createMedicalHistoryForm",
      medicalHistory,
      {
        root: true,
      }
    )

    dispatch(
      "applicationFormMedicalHistory/setMedicalHistoryTestings",
      medicalHistory.testings,
      { root: true }
    )

    dispatch(
      "applicationFormPaymentAuthorization/createPaymentAuthorizationForm",
      paymentAuthorization,
      {
        root: true,
      }
    )

    let initialFeeDiscounts = discounts.filter(
      (discount) =>
        discount.type_id === state.initialEvaluationFeeDiscountTypeId
    )

    let monthlyFeeDiscounts = discounts.filter(
      (discount) => discount.type_id === state.monthlyFeeDiscountTypeId
    )

    dispatch("applyInitialFeeDiscounts", initialFeeDiscounts)

    dispatch("applyMonthlyFeeDiscounts", monthlyFeeDiscounts)

    dispatch(
      "applicationFormEducationalHistory/createEducationalHistoryForm",
      educationalHistory,
      {
        root: true,
      }
    )

    dispatch(
      "applicationFormEducationalHistory/createApplicationFormEducationalPlacements",
      applicationForm.type.category.educationalPlacements,
      {
        root: true,
      }
    )

    dispatch(
      "applicationFormEducationalHistory/createEducationalPlacementsForm",
      educationalPlacements,
      {
        root: true,
      }
    )
  },
  formatDateToMySQL({ commit }, data) {
    commit("FORMAT_DATE_TO_MYSQL", data)
  },
  setEducations({ commit }, educations) {
    commit("SET_EDUCATIONS", educations)
  },
  setChapters({ commit }, chapters) {
    commit("SET_CHAPTERS", chapters)
  },
  setPageAssignments({ commit }, pageAssignments) {
    commit("SET_PAGE_ASSIGNMENTS", pageAssignments)
  },
  setApplicationFormCompletedByAll({ commit }, applicationFormCompletedByAll) {
    commit(
      "SET_APPLICATION_FORM_COMPLETED_BY_ALL",
      applicationFormCompletedByAll
    )
  },
  setApplicationFormClientLiveWithAll(
    { commit },
    applicationFormClientLiveWithAll
  ) {
    commit(
      "SET_APPLICATION_FORM_CLIENT_LIVE_WITH_ALL",
      applicationFormClientLiveWithAll
    )
  },
  setGenders({ commit }, genders) {
    commit("SET_GENDERS", genders)
  },
  deleteProfilePhoto({ commit, dispatch, state }) {
    commit("SET_DELETING_PHOTO", true)

    return apolloClient
      .mutate({
        mutation: ApplicationFormDeletePhoto,
        variables: {
          id: state.application.id,
        },
      })
      .then(() => {
        commit("SET_APPLICATION_PHOTO", null)
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_DELETING_PHOTO", false)
      })
  },
  photoChanged({ commit }, applicationForm) {
    commit("SET_APPLICATION_PHOTO", applicationForm.photo)
  },
  autoSave({ commit, state, rootState, getters, rootGetters, dispatch }) {
    commit("SET_AUTOSAVING", true)

    let variables = getVariables(state, rootState, getters, rootGetters)

    return apolloClient
      .mutate({
        mutation: UpdateApplicationForm,
        variables,
      })
      .catch((error) => {
        dispatch("applicationFormNotification/handleError", error, {
          root: true,
        })
      })
      .finally(() => {
        commit("SET_AUTOSAVING", false)
      })
  },
  submit({ commit, state, rootState, getters, rootGetters, dispatch }, vm) {
    dispatch("applicationFormNotification/clear", null, {
      root: true,
    })

    dispatch(
      "applicationFormValidation/validatePaymentAuthorizationPage",
      null,
      {
        root: true,
      }
    ).then(() => {
      if (
        rootState.applicationFormValidation.paymentAuthorizationValidation.hasNoErrors()
      ) {
        commit("SET_LOADING", true)

        let variables = getVariables(state, rootState, getters, rootGetters)
        variables["applicationForm"]["status_id"] = APPLICATION_FORM_SUBMITTED

        return apolloClient
          .mutate({
            mutation: UpdateApplicationForm,
            variables,
          })
          .then(() => {
            vm.$emit("next")
          })
          .catch((error) => {
            dispatch("applicationFormNotification/handleError", error, {
              root: true,
            })
          })
          .finally(() => {
            commit("SET_LOADING", false)
          })
      }
    })
  },
}

export const getters = {
  processing(state) {
    return state.loading || state.autosaving || state.deletingPhoto
  },
  completedByOther(state) {
    return (
      state.applicationForm.completed_by_id ===
      APPLICATION_FORM_COMPLETED_BY_OTHER
    )
  },
  liveWithOther(state) {
    return APPLICATION_FORM_CLIEN_LIVE_WITH_OTHER_STATUSES.includes(
      state.applicationForm.client_live_with_id
    )
  },
  isChild(state) {
    return (
      state.application.type.category_id === APPLICATION_FORM_CATEGORY_CHILD
    )
  },
  isAdult(state) {
    return (
      state.application.type.category_id === APPLICATION_FORM_CATEGORY_ADULT
    )
  },
  todayDate(state) {
    return state.applicationForm.today_date
      ? moment(state.applicationForm.today_date).format("ll")
      : ""
  },
  canDelete(state) {
    return !state.deletingPhoto && state.applicationPhoto
  },
  photo(state) {
    return state.applicationPhoto ? state.applicationPhoto : NO_PROFILE_IMAGE
  },
  condition(state) {
    return (
      state.genders.length > 0 &&
      state.educations.length > 0 &&
      state.chapters.length > 0 &&
      state.applicationFormClientLiveWithAll.length > 0 &&
      state.applicationFormCompletedByAll.length > 0 &&
      state.familyApplicationFormPageAssignments
    )
  },
  createdAt(state) {
    return state.applicationForm.created_at
      ? moment(state.applicationForm.created_at).format("ll")
      : ""
  },
  updatedAt(state) {
    return state.applicationForm.updated_at
      ? moment(state.applicationForm.updated_at).format("ll")
      : ""
  },
  isDraft(state) {
    return state.applicationForm.status_id === APPLICATION_FORM_DRAFT
  },
  isSubmitted(state) {
    return state.applicationForm.status_id === APPLICATION_FORM_SUBMITTED
  },
  isIncomplete(state) {
    return state.applicationForm.status_id === APPLICATION_FORM_INCOMPLETE
  },
  isAssigned(state) {
    return state.applicationForm.status_id === APPLICATION_FORM_ASSIGNED
  },
  isArchived(state) {
    return state.applicationForm.status_id === APPLICATION_FORM_ARCHIVED
  },
  discounts(state) {
    return [...state.initialFeeDiscounts, ...state.monthlyFeeDiscounts]
  },
  appliedDiscounts(state, getters) {
    return getters.discounts.map((item) => ({
      discount_id: item.id,
      name: item.name.name,
      price: item.price,
    }))
  },
  guides(state) {
    let result = []

    for (const property in state.guideForm) {
      if (state.guideForm[property]) {
        result.push({ guide_id: parseInt(property) })
      }
    }

    return result
  },
}

function getVariables(state, rootState, getters, rootGetters) {
  return {
    applicationForm: state.applicationForm,
    agreement: rootState.applicationFormAgreement.agreementForm,
    aware: rootState.applicationFormAware.awareForm,
    behaviour: rootState.applicationFormBehaviour.behaviourForm,
    developmentalHistory:
      rootState.applicationFormDevelopmentalHistory.developmentalHistoryForm,
    goal: rootState.applicationFormGoal.goalForm,
    educationalHistory:
      rootState.applicationFormEducationalHistory.educationalHistoryForm,
    health: rootState.applicationFormHealth.healthForm,
    medicalHistory: rootState.applicationFormMedicalHistory.medicalHistoryForm,
    paymentAuthorization:
      rootState.applicationFormPaymentAuthorization.paymentAuthorizationForm,
    info: rootState.applicationFormInfo.infoForm,
    motherInfo: rootState.applicationFormInfo.motherInfoForm,
    fatherInfo: rootState.applicationFormInfo.fatherInfoForm,
    guardianInfo: rootState.applicationFormInfo.guardianInfoForm,
    spouseInfo: rootState.applicationFormInfo.spouseInfoForm,

    guides: getters.guides,
    behaviourDisorders:
      rootGetters["applicationFormBehaviour/behaviourDisorderAssignments"],
    discounts: getters.appliedDiscounts,
    educationalPlacements:
      rootGetters["applicationFormEducationalHistory/educationalPlacements"],
    hands: rootGetters["applicationFormSkill/handPreferences"],

    eyeDiseases: rootGetters["applicationFormHealth/eyeDiseases"],
    products: rootGetters["applicationFormHealth/productPeriods"],
    specialists: rootGetters["applicationFormHealth/specialists"],

    motorSkillDisorders:
      rootGetters["applicationFormSkill/motorSkillDisorders"],
    languageSkills: rootGetters["applicationFormSkill/languages"],
    mathSkills: rootGetters["applicationFormSkill/maths"],
    cognitiveSkills: rootGetters["applicationFormSkill/cognitives"],
  }
}
