<template>
  <BaseLabel v-bind="$attrs" :label="label" v-if="label" />

  <textarea
    ref="textarea"
    :class="disabledClass"
    :value="value"
    disabled="true"
  ></textarea>
</template>

<script>
import { inputMixin } from "@/mixins/form.js"

export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  mixins: [inputMixin],
  mounted() {
    this.resize()
  },
  methods: {
    resize() {
      let element = this.$refs["textarea"]
      element.style.height = "5px"
      element.style.height = element.scrollHeight + "px"
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  overflow: hidden;
}
</style>
